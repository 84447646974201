import * as React from 'react';

const ShrinkPage = () => {
  const [url, setURL] = React.useState<string>('');
  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const [buttonText, setButtonText] = React.useState<string>('copy me');
  const [shrink, setShrink] = React.useState<string>();

  const submitHandler = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    const res = await fetch('https://go.vann.io', {
      body: JSON.stringify({ url }),
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      },
      method: 'post',
    });

    const { data } = await res.json();
    setIsLoading(false);
    setShrink(data.shrink);
  };

  return (
    <div>
      <h2>Hi, I’m Van</h2>
      <p>
        This is my <strong>Shrink</strong> tool that makes URLs a little{' '}
          <sup>
            bit <sup>tinier</sup>
          </sup>
      </p>
      <p>
        Have you got a long URL that you've always wanted to shrink?<br/>Submit
        it below and watch the magic happen!
      </p>
      <p>
        <strong>tl;dr</strong> This is a URL shortener ✂️
      </p>
      <br></br>
      <form className="form" onSubmit={submitHandler}>
        {shrink ? (
          <>
            <div className="flex">
              <input type="url" disabled={true} value={shrink} />
              <input type="button" onClick={() => {
                navigator.clipboard.writeText(shrink);
                setButtonText('copied!');
              }} value={buttonText} />
            </div>
            <p>✨ tada! ✨</p>
          </>
        ) : (
          <div className="flex">
            <input
              type="url"
              value={url}
              disabled={isLoading}
              placeholder="https://"
              onChange={(e) => setURL(e.target.value)}
              required={true}
              autoFocus
            />
            <input type="submit" value="abracadabra" />
          </div>
        )}
      </form>
    </div>
  );
};

export default ShrinkPage;
