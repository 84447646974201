import * as React from 'react';

import Layout from '../components/Layout';
import ShrinkPage from '../components/ShrinkPage';

export default () => (
  <Layout>
    <ShrinkPage />
  </Layout>
);
